var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-cy":"4208","fluid":""}},[_c('v-row',{attrs:{"data-cy":"4209"}},[_c('v-col',{attrs:{"data-cy":"4210"}},[_c('div',{staticClass:"d-block mt-2",attrs:{"data-cy":"4211"}},[_c('span',{ref:"screens_description_text",attrs:{"data-cy":"4219"}},[_vm._v(_vm._s(_vm.$t("1392")))]),_c('a',{ref:"screens_help_center_link",staticClass:"link secondary--text",staticStyle:{"text-decoration":"none"},attrs:{"data-cy":"4212","href":_vm.screensHelpCenterUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("1421")))]),(_vm.isWorkflowsEnabled)?_c('v-btn',{ref:"button_create_screen",staticClass:"float-right",attrs:{"data-cy":"4213","color":"primary"},on:{"click":function($event){_vm.showAddScreenDialog = true}}},[_vm._v(_vm._s(_vm.$t("1481")))]):_vm._e()],1)])],1),_c('v-row',{attrs:{"data-cy":"4130"}},[_c('v-col',{attrs:{"data-cy":"4131"}},[_c('v-data-table',{ref:"table_screens",staticClass:"elevation-1",attrs:{"data-cy":"4129","items":_vm.screensForTable,"headers":_vm.headersForTable,"footer-props":_vm.footersForTable,"items-per-page":_vm.$TABLES.SCREENS.itemsPerPage,"loading":_vm.isLoadingScreens || _vm.isLoadingWorkflows},on:{"click:row":function (item) { return _vm.$router.push({ name: 'screen', params: { id: item.id } }); }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref ){
var item = ref.item;
return [_c('v-btn',{staticClass:"float-right",attrs:{"data-cy":"4132","icon":""}},[_c('v-icon',{ref:"icon_chevron_right",attrs:{"data-cy":"4202"}},[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.workflowUsedIn",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-block text-truncate max-width-500",attrs:{"data-cy":"4201"}},on),[_c('span',{staticClass:"info--text",attrs:{"data-cy":"4207"}},[_vm._v(_vm._s(item.workflowUsedIn))])])]}}],null,true)},[_c('span',{attrs:{"color":"info darken-4","data-cy":"4200"}},[_vm._v(_vm._s(item.workflowUsedIn))])])]}}])})],1)],1),(_vm.showAddScreenDialog)?_c('v-dialog',{ref:"screen_add_dialog",attrs:{"data-cy":"4135","value":_vm.showAddScreenDialog,"width":"650","persistent":""}},[_c('validation-observer',{attrs:{"data-cy":"4136"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"4203"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleAddScreen)}}},[_c('v-card',{staticClass:"pa-2",attrs:{"data-cy":"4138"}},[_c('v-card-title',{attrs:{"data-cy":"4139"}},[_vm._v(_vm._s(_vm.$t("1481")))]),_c('v-card-text',{attrs:{"data-cy":"4140"}},[_c('themis-input',{ref:"themis_input_screen_name",attrs:{"data-cy":"4141","p-rules":("required|duplicate:" + _vm.isScreenNameDuplicate + "|max:" + _vm.screenNameCharacterLimit),"p-label":_vm._f("required")(("" + (_vm.$t('1485')))),"p-label-class":"secondary--text","p-name":"1485"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:"screen_name_input",attrs:{"data-cy":"4204","color":"secondary","placeholder":_vm.$t('345'),"disabled":_vm.isAddingScreen,"error-messages":errors,"outlined":""},on:{"focus":function($event){return _vm.handleScreenNameInputOnFocusEvent(onFocus)},"blur":function($event){return _vm.handleScreenNameInputOnBlurEvent(onBlur)}},model:{value:(_vm.screen.name),callback:function ($$v) {_vm.$set(_vm.screen, "name", $$v)},expression:"screen.name"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end pa-4",attrs:{"data-cy":"4220"}},[_c('v-btn',{ref:"button_cancel_add_screen",attrs:{"data-cy":"4221","disabled":_vm.isAddingScreen,"text":""},on:{"click":_vm.handleCancelAddScreen}},[_vm._v(_vm._s(_vm.$t("1425")))]),_c('v-btn',{ref:"button_add_screen",staticClass:"ml-4",attrs:{"data-cy":"4206","color":"primary","type":"submit","disabled":!_vm.isScreenNameValid,"loading":_vm.isAddingScreen}},[_vm._v(_vm._s(_vm.$t("1426")))])],1)],1)],1)]}}],null,false,3639014758)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }